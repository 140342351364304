import { render, staticRenderFns } from "./TransportFastListPlanDet.vue?vue&type=template&id=038223ac&scoped=true"
import script from "./TransportFastListPlanDet.vue?vue&type=script&lang=js"
export * from "./TransportFastListPlanDet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038223ac",
  null
  
)

export default component.exports